<template>
  <v-row class="deleted">
    <v-col cols="12">
      <form autocomplete="off">
        <search-meal-guide :form-data="form" />
      </form>
      <v-btn class="primary--text float-right mt-n5" @click="searchMealGuide">
        <span>Search for meal guides</span>
      </v-btn>
    </v-col>

    <v-col cols="12">
      <strong v-if="hasListData">Results found: {{ listMeta.total }}</strong>

      <v-card outlined class="px-4 py-4 mt-2">
        <meals-list-table
          :list="list"
          :loading="loading"
          :meta="listMeta"
          list-for="Deleted"
          @loadMore="loadMoreContent"
          @goTo="goTo"
        />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import SearchMealGuide from '@/components/forms/foods/SearchMealGuide'
import MealsListTable from '../components/ListTable'
import Form from '@/utils/form'

export default {
  name: 'DeletedMealGuidePage',

  components: {
    MealsListTable,
    SearchMealGuide,
  },

  data() {
    return {
      loading: false,
      form: new Form({
        body_type: null,
        restriction_code: null,
        template_number: null,
        calories: null,
      }),
    }
  },

  computed: {
    ...mapState({
      filter: (state) => state.meals.filter,
      bodyTypes: (state) => state.meals.extra.bodyTypes,
      list: (state) => state.meals.deleted.list,
      listMeta: (state) => state.meals.deleted.listMeta,
    }),

    hasListData() {
      return !!this.list.length
    },
  },

  created() {
    this.setFilter({ deleted: true })
  },

  methods: {
    ...mapActions({
      getGuideList: 'meals/getGuideList',
    }),

    ...mapMutations({
      setFilter: 'meals/setFilter',
      clearList: 'meals/clearList',
    }),

    async searchMealGuide() {
      this.clearList()
      this.fetchListData()

      await this.fetchListData()
    },

    async fetchListData(page = 1) {
      if (this.loading) return

      let params = {
        restrictionCode: this.form.restriction_code,
        templateNumber: this.form.template_number,
        bodyType: this.form.body_type,
        calories: this.form.calories,
        status: 'deleted',
        page,
      }

      this.loading = true

      await this.getGuideList(params)

      this.loading = false
    },

    loadMoreContent() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchListData(this.listMeta.current_page + 1)
      }
    },

    goTo(id) {
      this.$router.push({ name: 'form.meals', params: { id } })
    },
  },
}
</script>
